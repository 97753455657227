<template>
	<div class="body-function">
		<ul>
			<li>
				<span>日期：</span>
				<p>{{ topInfoList.start_date }}至{{ topInfoList.end_date }}</p>
			</li>
			<li>
				<span>运动队：</span>
				<p>{{ personInfo.department_name }}</p>
			</li>
			<li>
				<span>运动员：</span>
				<p>{{ personInfo.name }}</p>
			</li>
			<li>
				<span>测试指标：</span>
				<p>{{ topInfoList.norm_uuid_str }}</p>
			</li>
			<li>
				<span>创建人：</span>
				<p>{{ topInfoList.create_by }}</p>
			</li>
			<li>
				<span>创建日期：</span>
				<p>{{ topInfoList.create_time }}</p>
			</li>
			<span class="return_back" @click="returnBack"><i class="iconfont iconfanhui"></i>返回</span>
		</ul>

		<div style="display: inline-block; width: 100%; height: 100%">
			<div class="individual_top_content">
				<div class="member_content_top">
					<!-- <el-avatar :src="interDataList[0].head_img ? 'http://' + interDataList[0].head_img : circleUrl"></el-avatar> -->
					<img :src="personInfo.head_img ? 'http://' + personInfo.head_img : circleUrl" alt="" />
					<span>姓名: {{ personInfo.name }}</span>
					<span>性别：{{ personInfo.sex == 1 ? '男' : personInfo.sex == 2 ? '女' : '' }}</span>
					<span>身高：{{ personInfo.height }}cm</span>
					<span>体重：{{ personInfo.weight }}kg</span>
					<span>项目：{{ personInfo.sport_event_name }}</span>
					<span>运动队：{{ personInfo.department_name }}</span>
				</div>
				<div class="top_title">
					<span></span>
					数据详情
				</div>
				<div class="top_sort_content">
					<div style="flex: 1">
						<!-- <el-tooltip class="item" effect="dark" content="请选出数值越小，代表能力越强的指标" placement="top">
							<i class="el-icon-info"></i>
						</el-tooltip> -->
						倒序指标: {{ norm_uuid_str_selected_name }}

						<!-- <el-select v-model="sortListValue" multiple placeholder="请选择" @change="sortChange">
							<el-option v-for="item in demoSelectData" :key="item.uuid" :label="item.name" :value="item.uuid"> </el-option>
						</el-select> -->
					</div>
					<div><span></span>最大值 <span></span>最小值</div>
				</div>

				<el-table :data="tableDataList" style="width: 100%" class="sortTable" ref="analysisTable" height="380">
					<el-table-column label="序号" type="index" width="45" align="center" fixed>
						<template slot-scope="scope">{{ scope.$index + 1 == tableDataList.length || scope.$index + 1 == tableDataList.length - 1 ? '' : scope.$index + 1 }}</template>
					</el-table-column>
					<el-table-column label="日期" prop="key" align="center" width="150" fixed> </el-table-column>
					<el-table-column v-for="(item, index) in tableDataHead" :key="'table' + index" :prop="item.uuid" :label="item.norm_name" align="center">
						<template slot-scope="scope">
							<div
								class="valueContent"
								:style="{
									background:
										scope.row[item.uuid].order == 2 && sortListValue.indexOf(item.uuid) !== -1
											? '#ffcb00'
											: scope.row[item.uuid].order == 1 && sortListValue.indexOf(item.uuid) !== -1
											? '#80e386'
											: scope.row[item.uuid].order == 2
											? '#80e386'
											: scope.row[item.uuid].order == 1
											? '#ffcb00'
											: '',
								}"
							>
								{{ scope.$index + 1 == tableDataList.length || scope.$index + 1 == tableDataList.length - 1 ? scope.row[item.uuid] || '-' : scope.row[item.uuid].value || '-' }}
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="individual_top_content">
				<div class="top_title_qs">
					<div class="top_title">
						<span></span>
						指标变化趋势
					</div>
				</div>

				<div class="individual_echarts_content">
					<div v-for="(item, index) in EchartsData" :key="'ech' + index" :id="'bgEcharts' + index" class="bgEcharts"></div>
				</div>
			</div>

			<!-- 测试评价 -->
			<div class="test_content">
				<div class="top_title">
					<span></span>
					测试评价
				</div>
				<!-- v-if="$route.query.name !== 'look'" -->
				<div class="test_editor" id="editor" ref="editorElem" style="width: 100%"></div>
				<!-- <div class="test_editor look_editor" v-else v-html="editorContent">

        </div>-->
			</div>
		</div>
	</div>
</template>

<script>
import E from 'wangeditor'

export default {
	data() {
		return {
			editor: null,
			editorContent: '',
			circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
			personInfo: {},

			scopeDate: [],

			settingDateDialog: false,
			newDateStageData: [],

			tableDataHead: [],
			tableDataList: [],
			EchartsData: [],

			sortListValue: [],

			norm_uuid_str_selected_name: '',
			topInfoList: {},
		}
	},
	watch: {
		tableDataHead: {
			handler() {
				this.$nextTick(() => {
					this.$refs.analysisTable.doLayout()
				})
			},
			deep: true,
		},
	},
	mounted() {
		this.editor = new E(this.$refs.editorElem)
		// 编辑器的事件，每次改变会获取其html内容
		this.editor.config.onchange = html => {
			this.editorContent = html
		}
		this.editor.config.zIndex = 1000
		// this.editor.config.uploadImgServer = this.$store.state.img_url + '/p/annex/add'
		// this.editor.config.uploadFileName = 'file'
		this.editor.config.uploadImgShowBase64 = true
		this.editor.config.menus = [
			// 菜单配置
			'head', // 标题
			'bold', // 粗体
			'fontSize', // 字号
			'fontName', // 字体
			'italic', // 斜体
			'underline', // 下划线
			'strikeThrough', // 删除线
			'foreColor', // 文字颜色
			'backColor', // 背景颜色
			'link', // 插入链接
			'list', // 列表
			'justify', // 对齐方式
			'quote', // 引用
			'image', // 插入图片
			'table', // 表格
			'code', // 插入代码
		]
		this.editor.create() // 创建富文本实例

		this.$axios
			.post('/p/analyse/read', {
				uuid: this.$route.query.uuid,
			})
			.then(res => {
				if (res.data.code == 0) {
					this.personInfo = res.data.data.detail.staff_info
					this.EchartsData = res.data.data.detail.chart
					this.tableDataHead = res.data.data.detail.norm_info
					this.tableDataList = res.data.data.detail.row

					this.norm_uuid_str_selected_name = res.data.data.norm_uuid_str_selected_name

					this.sortListValue = res.data.data.norm_uuid_str_selected.split(',')

					this.newDateStageData = JSON.parse(res.data.data.date_str)

					this.editorContent = res.data.data.report
					this.editor.txt.html(res.data.data.report)
					this.editor.disable()

					this.topInfoList = {
						start_date: res.data.data.start_date,
						end_date: res.data.data.end_date,
						norm_uuid_str: res.data.data.norm_uuid_str,
						create_by: res.data.data.create_by,
						create_time: res.data.data.create_time,
					}

					this.EchartsData.forEach((itm, idx) => {
						this.$nextTick(() => {
							this.setEcharts(itm, idx)
						})
					})
				} else {
					this.$message({
						type: 'error',
						message: res.data.message,
					})
				}
			})
	},
	methods: {
		setEcharts(rows, idx) {
			var name = 'bgEcharts' + idx
			var backgrounds = [
					'#D6FFD9',
					'#DBECFF',
					'#FFFCBB',
					'#FFE5E5',
					'#E1D6FF',
					'#D6FCFF',
					'#D6FFD9',
					'#DBECFF',
					'#FFFCBB',
					'#FFE5E5',
					'#E1D6FF',
					'#D6FCFF',
					'#D6FFD9',
					'#DBECFF',
					'#FFFCBB',
					'#FFE5E5',
					'#E1D6FF',
					'#D6FCFF',
				],
				colors = [
					'#56C15D',
					'#5C87B8',
					'#9B950C',
					'#CC6969',
					'#856ACE',
					'#4BA9B0',
					'#56C15D',
					'#5C87B8',
					'#9B950C',
					'#CC6969',
					'#856ACE',
					'#4BA9B0',
					'#56C15D',
					'#5C87B8',
					'#9B950C',
					'#CC6969',
					'#856ACE',
					'#4BA9B0',
				]
			var x_data = [],
				y_data = []
			for (var i in rows.data) {
				x_data.push(i)
				y_data.push(rows.data[i])
			}
			var oldStageData = JSON.parse(JSON.stringify(this.newDateStageData))
			var newStageData = this.getDateTime(oldStageData, x_data)
			let serum = this.$echarts.init(document.getElementById(name))
			var optionS = {
				title: {
					text: rows.norm_name + '历史成绩',
					// textAlign: 'center',
					// textVerticalAlign: 'center',
					top: '5%',
					left: 'center',
				},
				grid: {
					top: '25%',
					left: '10%',
					right: '10%',
					bottom: '20%',
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
					},
				},
				xAxis: {
					type: 'category',
					data: x_data,
					nameLocation: 'end',
					boundaryGap: ['20%', '20%'],
					alignWithLabel: true,
					axisLabel: {
						color: '#333',
						rotate: 40,
						margin: 10,
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: '单位: ' + rows.unit_name ? rows.unit_name : '',
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 10, 0],
					},
				},
				// visualMap: {
				// 	show: false,
				// 	dimension: 0,
				// 	pieces: [],
				// },
				color: ['#1790FF'],
				series: [
					{
						name: '',
						type: 'line',
						smooth: false,
						symbolSize: 10,
						// prettier-ignore
						data: y_data,
						label: {
							show: true,
						},
						markLine: {
							data: [
								{
									type: 'average',
									name: 'Avg',
									label: {
										show: true,
										formatter: '平均值:{c}',
										position: 'insideEndTop',
									},
								},
							],
						},
						markArea: {
							data: [],
							itemStyle: {
								color: 'rgba(255, 0, 0, 0.3)', // 设置 markArea 的背景颜色
							},
						},
					},
				],
			}
			var f_data = []
			newStageData.forEach((item, index) => {
				if (!item.readTime[1]) {
					return false
				}
				f_data.push([
					{
						name: item.title,
						xAxis: item.readTime ? item.readTime[0] : '',
						itemStyle: {
							color: backgrounds[index],
							borderWidth: 10,
							borderColor: backgrounds[index],
						},
						label: {
							offset: [0, 30],
							color: colors[index],
						},
					},
					{
						xAxis: item.readTime ? item.readTime[1] : '',
					},
				])
			})
			optionS.series[0].markArea.data = f_data
			serum.setOption(optionS, false)
			this.$echartsResize(serum)
		},

		getDateTime(oldStageData, valueData) {
			oldStageData.forEach((itm, idx) => {
				itm.readTime = []
				var start_idx = '',
					end_idx = ''
				var timeStart = new Date(itm.date[0]).getTime()
				var timeEnd = new Date(itm.date[1]).getTime()
				for (var i = 0; i < valueData.length; i++) {
					var cime = new Date(valueData[i]).getTime()
					if (timeStart <= cime && timeEnd >= cime) {
						start_idx = i
						break
					}
				}
				for (var i = valueData.length - 1; i >= 0; i--) {
					var cime = new Date(valueData[i]).getTime()
					if (timeEnd >= cime && cime >= timeStart) {
						end_idx = i
						break
					}
				}
				itm.readTime = [valueData[start_idx], valueData[end_idx]]
			})
			return oldStageData
		},

		returnBack() {
			this.$router.push({ path: '/analysis/reportManagement', query: { type: 2, page: this.$route.query.page } })
		},
	},
}
</script>

<style lang="scss" scoped>
.body-function {
	padding-top: 10px;
	ul {
		background: #fff;
		margin-bottom: 10px;
		border-radius: 5px;
		display: flex;
		flex-wrap: wrap;
		padding: 0 0 40px 20px;
		position: relative;
		.return_back {
			position: absolute;
			right: 30px;
			top: 30px;
			font-size: 16px;
			i {
				margin-right: 8px;
			}
			&:hover {
				cursor: pointer;
			}
		}
		li {
			width: 29%;
			min-height: 40px;
			font-size: 16px;
			margin-right: 40px;
			margin-top: 20px;
			display: flex;
			align-items: center;
			color: #333;
			span {
				display: inline-block;
			}
			p {
				flex: 1;
				color: #666;
				margin-left: 10px;
				min-height: 40px;
				line-height: 40px;
				border-bottom: 2px solid #ccc;
			}
		}
	}
}
.child-top {
	border-radius: 5px;
	.input-from {
		// float: left;
		flex: 1;
		min-width: 1100px;
		margin-bottom: 0;

		.picker_date {
			float: left;
			margin-top: 20px;
		}

		& > .el-select {
			width: 150px;
			float: left;
			margin-top: 20px;
		}

		.el-select,
		.el-input {
			margin-top: 20px;
		}

		.el-input.el-input--suffix.left-child-input {
			float: left;
			width: 200px;
		}

		.el-cascader {
			margin-top: 18px;
			min-width: 200px;
		}

		.searchBtn {
			margin-top: 20px;
		}
	}

	.operation-button {
		// float: left;
		margin-bottom: 10px;
		.el-button {
			width: auto;
		}
	}
}

.individual_top_content {
	padding: 20px;
	margin-bottom: 10px;
	background: #fff;
	border-radius: 5px;
}

.member_content_top {
	height: 70px;
	display: flex;
	align-items: center;
	padding-left: 15px;
	margin-bottom: 20px;
	border: 1px solid #e3e3e3;
	.el-avatar {
		width: 50px;
		height: 50px;
		line-height: 50px;
	}
	img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}
	span {
		font-size: 18px;
		color: #333;
		margin-left: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		display: inline-block;
		max-width: 300px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		word-break: break-all;
		&:first-child {
			margin-left: 0px;
		}
		&:nth-child(2) {
			margin-left: 20px;
		}
	}
}

.top_title_qs {
	display: flex;
	justify-content: space-between;
	.el-button {
		border: 1px #0055e9 solid;
		color: #0055e9;
	}
}
.individual_echarts_content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	div {
		width: 48%;
		height: 400px;
		border: 1px solid #e3e3e3;
		margin-top: 20px;
	}
}
.top_title {
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #333;
	font-weight: 500;
	font-family: PingFangSC-Medium, PingFang SC;
	span {
		display: inline-block;
		width: 6px;
		height: 20px;
		background: #0055e9;
		border-radius: 3px;
		margin-right: 10px;
	}
}

.top_sort_content {
	color: #666;
	font-size: 16px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 20px;
	i {
		font-size: 20px;
		margin-right: 20px;
	}
	span {
		display: inline-block;
		width: 18px;
		height: 14px;
		background: #80e386;
		margin-left: 20px;
		margin-right: 5px;
		&:last-child {
			background: #ffcb00;
		}
	}
	.el-select {
		margin-left: 15px;
		width: 50%;
	}
}
.sortTable {
	margin-left: 20px;
	border: 1px solid #ebeef5;

	/deep/ tbody > tr > td {
		padding: 0 !important;
	}
	/deep/ .cell {
		padding: 0 !important;
	}
	.valueContent {
		height: 50px;
		line-height: 50px;
		// line-height: 30px;
		// padding: 10px 8px;
	}
}

.add_stageContent {
	margin-bottom: 10px;
	text-align: right;
}

.addressForm_dialog {
	.el-table {
		border: 1px solid #e3e3e3;

		.el-button {
			color: #424242;
			&:hover {
				color: #ff5656;
			}
		}
	}
	.el-date-editor {
		margin-right: 1.04167rem;
		border-radius: 0;
		border: none;
		border-bottom: 2px solid #ccc;
	}
}

.ModifyDoalog {
	display: flex;
	height: 400px;
	padding: 0 30px;
	justify-content: space-between;
	margin-bottom: 10px;
	.textDialog_left {
		width: 45%;
		border: 1px solid #e3e3e3;
		overflow: hidden;
		.textDialog_left_input {
			padding: 10px 20px;
		}
		h2 {
			height: 50px;
			line-height: 50px;
			background: #f5f5f5;
			border-radius: 2px 2px 0px 0px;
			border: 1px solid #e3e3e3;
			border-left: none;
			border-right: none;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			color: #333;
			margin-top: 5px;
		}
		.tree_demo {
			height: 280px;
			overflow-y: auto;
			padding: 20px;
			box-sizing: border-box;
			.el-tree {
				font-size: 16px;
				/deep/ .el-tree-node {
					margin: 5px 0;
					.el-checkbox {
						margin-right: 10px;
						.is-checked {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
						.is-indeterminate {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
					}
					.el-tree-node__label {
						color: #333;
					}
				}
			}
		}
	}
	.textDialog_right {
		width: 45%;
		border: 1px solid #e3e3e3;
		p {
			height: 50px;
			line-height: 50px;
			background: #f5f5f5;
			border-radius: 2px 2px 0px 0px;
			border-bottom: 1px solid #e3e3e3;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			color: #333;
			position: relative;
			span {
				position: absolute;
				right: 20px;
				font-size: 14px;
				font-family: MicrosoftYaHei;
				color: #999999;
				&:hover {
					cursor: pointer;
				}
			}
		}
		.tree_demo {
			height: 345px;
			overflow-y: auto;
			padding: 20px;
			box-sizing: border-box;
			.el-tree {
				font-size: 16px;
				/deep/ .el-tree-node {
					margin: 5px 0;
					.el-checkbox {
						margin-right: 10px;
						.is-checked {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
						.is-indeterminate {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
					}
					.el-tree-node__label {
						color: #333;
					}
				}

				.custom-tree-node {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 14px;
					padding-right: 8px;
					i {
						&:hover {
							color: #f00;
						}
					}
				}
			}
		}
	}
}

.text_demo {
	width: 30%;
	min-height: 40px;
	line-height: 40px;
	border-bottom: 2px solid #ccc;
	padding-left: 5px;
	margin-top: 18px;
	float: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	span {
		padding: 5px 3px 5px 10px;
		border-radius: 5px;
		background-color: #f4f4f5;
		border-color: #e9e9eb;
		color: #909399;
		font-size: 14px;
		margin-right: 7px;
		margin-bottom: 3px;
		i {
			border-radius: 50%;
			background-color: #c0c4cc;
			font-size: 14px;
			color: #909399;
			margin-left: 5px;
			&:hover {
				color: #fff;
				cursor: pointer;
			}
		}
	}
	p {
		padding-left: 10px;
		color: #c2c2c2;
		font-size: 14px;
	}
}

// ------------------
// 测试评价
.test_content {
	background: #fff;
	height: auto;
	padding: 40px 100px 30px 50px;
	margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
	}
	.test_editor {
		width: 100%;
		margin-top: 40px;
		height: 400px;
	}
	.look_editor {
		overflow: auto;
		border: 1px solid #f5f7fa;
		border-radius: 5px;
		padding: 20px;
		box-sizing: border-box;
		/* table 样式 */
		table {
			border-top: 1px solid #ccc;
			border-left: 1px solid #ccc;
		}
		table td,
		table th {
			border-bottom: 1px solid #ccc;
			border-right: 1px solid #ccc;
			padding: 3px 5px;
		}
		table th {
			border-bottom: 2px solid #ccc;
			text-align: center;
		}

		/* blockquote 样式 */
		blockquote {
			display: block;
			border-left: 8px solid #d0e5f2;
			padding: 5px 10px;
			margin: 10px 0;
			line-height: 1.4;
			font-size: 100%;
			background-color: #f1f1f1;
		}

		/* code 样式 */
		code {
			display: inline-block;
			*display: inline;
			*zoom: 1;
			background-color: #f1f1f1;
			border-radius: 3px;
			padding: 3px 5px;
			margin: 0 3px;
		}
		pre code {
			display: block;
		}

		/* ul ol 样式 */
		ul,
		ol {
			margin: 10px 0 10px 20px;
		}
	}
}
</style>
